export default class NetworkingExponentGold {
  public static readonly SET_DATA = "NETWORKING_EXPONENT_GOLD_SET_DATA";
  public static readonly SET_STATUS = "NETWORKING_EXPONENT_GOLD_SET_STATUS";
  public static readonly SET_ERROR = "NETWORKING_EXPONENT_GOLD_SET_ERROR";
  public static readonly CLEAR = "NETWORKING_EXPONENT_GOLD_CLEAR";

  public static setData(data: any) {
    return {
      data,
      type: NetworkingExponentGold.SET_DATA,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: NetworkingExponentGold.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: NetworkingExponentGold.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: NetworkingExponentGold.CLEAR,
    };
  }
}
