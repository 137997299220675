import { combineReducers } from "redux";

import General from "./General";
import Gold from "./Gold";
import Silver from "./Silver";

export default combineReducers({
  General,
  Gold,
  Silver,
});
