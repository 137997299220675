import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  list: null,
  paginator: null,
  error: null,
};

export default (state = InitialState, action: any) => {
  switch (action.type) {
    case Action.Networking.Employee.ADD_LIST:
      return { ...state, list: (state.list || []).concat(action.data) };
    case Action.Networking.Employee.SET_PAGINATOR:
      return { ...state, paginator: action.data };
    case Action.Networking.Employee.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Networking.Employee.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Networking.Employee.CLEAR:
      return { ...state, list: null };
    default:
      return state;
  }
};
