import * as Action from "@actions/index";

export default (state = [], action: any): any => {
  switch (action.type) {
    case Action.Ui.Alert.OPEN:
      return [...state, action.data];
    case Action.Ui.Alert.CLOSE:
      const newState = state.filter((alert: any) => alert.data.index !== action.data);
      return newState;
    case Action.Ui.Alert.PATCH:
      const tempState = state.map((alert: any) =>
        alert.data.index === action.data.index
          ? { ...alert, data: { ...alert.data, ...action.data } }
          : alert
      );
      return tempState;
    case Action.Ui.Alert.CLOSE_ALL:
      return [];
    default:
      return state;
  }
};
