export default class MainUser {
  public static readonly SET_COUNT = "MAIN_USER_SET_COUNT";

  public static setCount(data: any) {
    return {
      data,
      type: MainUser.SET_COUNT,
    };
  }
}
