export default class UserCurrent {
  public static readonly SET_DATA = "USER_CURRENT_SET_DATA";
  public static readonly SET_STATUS = "USER_CURRENT_SET_STATUS";
  public static readonly SET_ERROR = "USER_CURRENT_SET_ERROR";
  public static readonly CLEAR = "USER_CURRENT_CLEAR";

  public static setData(data: any) {
    return {
      data,
      type: UserCurrent.SET_DATA,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: UserCurrent.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: UserCurrent.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: UserCurrent.CLEAR,
    };
  }
}
