export default class NetworkingEmployee {
  public static readonly SET_LIST = "NETWORKING_EMPLOYEE_SET_LIST";
  public static readonly ADD_LIST = "NETWORKING_EMPLOYEE_ADD_LIST";
  public static readonly SET_STATUS = "NETWORKING_EMPLOYEE_SET_STATUS";
  public static readonly SET_ERROR = "NETWORKING_EMPLOYEE_SET_ERROR";
  public static readonly CLEAR = "NETWORKING_EMPLOYEE_CLEAR";
  public static readonly SET_PAGINATOR = "NETWORKING_EMPLOYEE_SET_PAGINATOR";

  public static setList(data: any[]): any {
    return {
      data,
      type: NetworkingEmployee.SET_LIST,
    };
  }

  public static addList(data: any[]): any {
    return {
      data,
      type: NetworkingEmployee.ADD_LIST,
    };
  }

  public static setPaginator(data: any): any {
    return {
      data,
      type: NetworkingEmployee.SET_PAGINATOR,
    };
  }

  public static setStatus(data: string): any {
    return {
      data,
      type: NetworkingEmployee.SET_STATUS,
    };
  }

  public static setError(data: string): any {
    return {
      data,
      type: NetworkingEmployee.SET_ERROR,
    };
  }

  public static clear(): any {
    return {
      type: NetworkingEmployee.CLEAR,
    };
  }
}
