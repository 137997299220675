export default class BroadcastsSchedule {
  public static readonly SET_LIST = "BROADCASTS_SCHEDULE_SET_LIST";
  public static readonly SET_MODIFIED_LIST = "BROADCASTS_SCHEDULE_SET_MODIIFIED_LIST";
  public static readonly SET_STATUS = "BROADCASTS_SCHEDULE_SET_STATUS";
  public static readonly SET_ERROR = "BROADCASTS_SCHEDULE_SET_ERROR";
  public static readonly CLEAR = "BROADCASTS_SCHEDULE_CLEAR";

  public static setList(data: any) {
    return {
      data,
      type: BroadcastsSchedule.SET_LIST,
    };
  }

  public static setModifiedList(data: any) {
    return {
      data,
      type: BroadcastsSchedule.SET_MODIFIED_LIST,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: BroadcastsSchedule.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: BroadcastsSchedule.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: BroadcastsSchedule.CLEAR,
    };
  }
}
