export default class Current {
  public static readonly SET_DATA = "COMPANY_CURRENT_SET_DATA";
  public static readonly SET_STATUS = "COMPANY_CURRENT_SET_STATUS";
  public static readonly SET_ERROR = "COMPANY_CURRENT_SET_ERROR";
  public static readonly CLEAR = "COMPANY_CURRENT_CLEAR";

  public static setData(data: any) {
    return {
      data,
      type: Current.SET_DATA,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: Current.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: Current.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: Current.CLEAR,
    };
  }
}
