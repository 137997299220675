export default class Type {
  public static readonly SET_LIST = "COMPANY_TYPE_SET_LIST";
  public static readonly SET_GROUPS = "COMPANY_TYPE_SET_GROUPS";
  public static readonly SET_STATUS = "COMPANY_TYPE_SET_STATUS";
  public static readonly SET_ERROR = "COMPANY_TYPE_SET_ERROR";
  public static readonly CLEAR = "COMPANY_TYPE_CLEAR";

  public static setList(data: any) {
    return {
      data,
      type: Type.SET_LIST,
    };
  }

  public static setGroups(data: any) {
    return {
      data,
      type: Type.SET_GROUPS,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: Type.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: Type.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: Type.CLEAR,
    };
  }
}
