export default class NetworkingExponentSilver {
  public static readonly SET_DATA = "NETWORKING_EXPONENT_SILVER_SET_DATA";
  public static readonly SET_STATUS = "NETWORKING_EXPONENT_SILVER_SET_STATUS";
  public static readonly SET_ERROR = "NETWORKING_EXPONENT_SILVER_SET_ERROR";
  public static readonly CLEAR = "NETWORKING_EXPONENT_SILVER_CLEAR";

  public static setData(data: any) {
    return {
      data,
      type: NetworkingExponentSilver.SET_DATA,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: NetworkingExponentSilver.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: NetworkingExponentSilver.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: NetworkingExponentSilver.CLEAR,
    };
  }
}
