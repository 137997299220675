import { BaseAction } from "@interface/common";

export default class Days {
  public static readonly SET_DATA = "NETWORKING_DAYS_SET_DATA";

  public static setData(data: string[]): BaseAction<string[]> {
    return {
      type: Days.SET_DATA,
      data,
    };
  }
}
