export default class NetworkingCompany {
  public static readonly SET_SELLERS_COUNT = "NETWORKING_COMPANY_SET_SELLERS_COUNT";
  public static readonly SET_BUYERS_COUNT = "NETWORKING_COMPANY_SET_BUYERS_COUNT";
  public static readonly SET_LIST = "NETWORKING_COMPANY_SET_LIST";
  public static readonly ADD_LIST = "NETWORKING_COMPANY_ADD_LIST";
  public static readonly SET_STATUS = "NETWORKING_COMPANY_SET_STATUS";
  public static readonly SET_ERROR = "NETWORKING_COMPANY_SET_ERROR";
  public static readonly CLEAR = "NETWORKING_COMPANY_CLEAR";
  public static readonly SET_PAGINATOR = "NETWORKING_COMPANY_SET_PAGINATOR";

  public static setSellersCount(data: any): any {
    return {
      data,
      type: NetworkingCompany.SET_SELLERS_COUNT,
    };
  }

  public static setBuyersCount(data: any): any {
    return {
      data,
      type: NetworkingCompany.SET_BUYERS_COUNT,
    };
  }

  public static setList(data: any): any {
    return {
      data,
      type: NetworkingCompany.SET_LIST,
    };
  }

  public static addList(data: any): any {
    return {
      data,
      type: NetworkingCompany.ADD_LIST,
    };
  }

  public static setPaginator(data: any): any {
    return {
      data,
      type: NetworkingCompany.SET_PAGINATOR,
    };
  }

  public static setStatus(data: any): any {
    return {
      data,
      type: NetworkingCompany.SET_STATUS,
    };
  }

  public static setError(data: any): any {
    return {
      data,
      type: NetworkingCompany.SET_ERROR,
    };
  }

  public static clear(): any {
    return {
      type: NetworkingCompany.CLEAR,
    };
  }
}
