export default class Userbar {
  public static readonly SHOW = "UI_USERBAR_SHOW";
  public static readonly HIDE = "UI_USERBAR_HIDE";

  public static show() {
    return {
      data: { display: true },
      type: Userbar.SHOW,
    };
  }

  public static hide() {
    return {
      data: { display: false },
      type: Userbar.HIDE,
    };
  }
}
