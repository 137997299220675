import * as Action from "@actions/index";

const InitialState = {
  status: "loading",
  paginator: {},
  list: [],
  error: "",
};

export default (state = InitialState, action: any) => {
  switch (action.type) {
    case Action.Main.Rating.SET_LIST:
      return { ...state, list: action.data };
    case Action.Main.Rating.SET_PAGINATOR:
      return { ...state, paginator: action.data };
    case Action.Main.Rating.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Main.Rating.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Main.Rating.CLEAR:
      return { ...state, data: {} };
    default:
      return state;
  }
};
