import * as Action from "@actions/index";

const InitialState = {};

export default (state = InitialState, action: any) => {
  switch (action.type) {
    case Action.Schedule.SET_DATA:
      return { ...state, data: action.data };
    case Action.Schedule.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Schedule.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Schedule.CLEAR:
      return { ...state, data: {} };
    default:
      return state;
  }
};
