import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { i18nReducer } from "react-redux-i18n";

import * as Reducers from "@reducers/index";

const store = configureStore({
  reducer: combineReducers({ ...Reducers, i18n: i18nReducer }),
  devTools: true,
});
const dispatch = (action: { type?: string; data?: unknown }): unknown => store.dispatch(action);
const state = (): unknown => store.getState();
export { store as Store, dispatch as useDispatch, state as useState };
