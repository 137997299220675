import { combineReducers } from "redux";

import Incoming from "./Incoming";
import Outgoing from "./Outgoing";
import Calendar from "./Calendar";

export default combineReducers({
  Incoming,
  Outgoing,
  Calendar,
});
