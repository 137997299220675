import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  list: null,
  counter: null,
  error: null,
};

export default function UserEventsOutgoing(state = InitialState, action: any) {
  switch (action.type) {
    case Action.User.Events.Outgoing.SET_LIST:
      return { ...state, list: action.data };
    case Action.User.Events.Outgoing.SET_COUNTER:
      return { ...state, counter: action.data };
    case Action.User.Events.Outgoing.SET_STATUS:
      return { ...state, status: action.data };
    case Action.User.Events.Outgoing.SET_ERROR:
      return { ...state, error: action.data };
    case Action.User.Events.Outgoing.CLEAR:
      return { ...state, list: null };
    default:
      return state;
  }
}
