export default class AvatarChanger {
  public static readonly SET_RESULT = "AVATAR_CHANGER_SET_RESULT";

  public static setResult(image: string) {
    return {
      data: image,
      type: AvatarChanger.SET_RESULT,
    };
  }
}
