export default class MainTime {
  public static readonly SET_CURRENT = "MAIN_TIME_SET_COUNT";

  public static setCurrent(data: any) {
    return {
      data,
      type: MainTime.SET_CURRENT,
    };
  }
}
