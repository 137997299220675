export default class Header {
  public static readonly SHOW = "UI_HEADER_SHOW";
  public static readonly HIDE = "UI_HEADER_HIDE";

  public static show() {
    return {
      data: { display: true },
      type: Header.SHOW,
    };
  }

  public static hide() {
    return {
      data: { display: false },
      type: Header.HIDE,
    };
  }
}
