import React, { ReactElement } from "react";
import { Provider } from "react-redux";
import { Store } from "@helpers/Store";

import "@helpers/Translator";
import "@helpers/TimeIncrement";
import "moment/locale/de";
import "moment/locale/ru";

import ScrollToTop from "@helpers/ScrollToTop";
import Preloader from "@components/preloader/Preloader";

import "@public/scss/global/_index.scss";

const Language = React.lazy(() => import(/* webpackChunkName: 'ContainerLanguage' */ "./Language"));
const Modal = React.lazy(() => import(/* webpackChunkName: 'ContainerModal' */ "./Modal"));
const Alert = React.lazy(() => import(/* webpackChunkName: 'ContainerAlert' */ "./Alert"));

const App = (): ReactElement => (
  <Provider store={Store}>
    <ScrollToTop />
    <React.Suspense fallback={<Preloader />}>
      <Language />

      <React.Suspense fallback={<Preloader />}>
        <Modal />

        <React.Suspense fallback={<Preloader />}>
          <Alert />
        </React.Suspense>
      </React.Suspense>
    </React.Suspense>
  </Provider>
);

export default App;
