import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  count: 0,
  data: null,
  list: null,
  paginator: null,
  error: null,
};

export default function NetworkingExponent(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Networking.Exponent.Silver.SET_DATA:
      return { ...state, data: action.data };
    case Action.Networking.Exponent.Silver.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Networking.Exponent.Silver.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Networking.Exponent.Silver.CLEAR:
      return { ...state, data: {} };
    default:
      return state;
  }
}
