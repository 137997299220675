export default class UserEvents {
  public static readonly SET_STATUS = "USER_EVENTS_SET_STATUS";
  public static readonly SET_ERROR = "USER_EVENTS_SET_ERROR";
  public static readonly CLEAR = "USER_EVENTS_CLEAR";

  public static setStatus(data: any) {
    return {
      data,
      type: UserEvents.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: UserEvents.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: UserEvents.CLEAR,
    };
  }
}
