export default class MainRating {
  public static readonly SET_LIST = "MAIN_RATING_SET_LIST";
  public static readonly SET_PAGINATOR = "MAIN_RATING_SET_PAGINATOR";
  public static readonly SET_STATUS = "MAIN_RATING_SET_STATUS";
  public static readonly SET_ERROR = "MAIN_RATING_SET_ERROR";
  public static readonly CLEAR = "MAIN_RATING_CLEAR";

  public static setList(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: MainRating.SET_LIST,
    };
  }

  public static setPaginator(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: MainRating.SET_PAGINATOR,
    };
  }

  public static setStatus(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: MainRating.SET_STATUS,
    };
  }

  public static setError(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: MainRating.SET_ERROR,
    };
  }

  public static clear(): { type: string } {
    return {
      type: MainRating.CLEAR,
    };
  }
}
