export default class UserEventsCalendar {
  public static readonly SET_LIST = "USER_EVENTS_CALENDAR_SET_LIST";
  public static readonly SET_STATUS = "USER_EVENTS_CALENDAR_SET_STATUS";
  public static readonly SET_ERROR = "USER_EVENTS_CALENDAR_SET_ERROR";
  public static readonly CLEAR = "USER_EVENTS_CALENDAR_CLEAR";

  public static setList(data: any) {
    return {
      data,
      type: UserEventsCalendar.SET_LIST,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: UserEventsCalendar.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: UserEventsCalendar.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: UserEventsCalendar.CLEAR,
    };
  }
}
