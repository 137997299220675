export default class Country {
  public static readonly SET_LIST = "LOCATION_COUNTRY_SET_LIST";
  public static readonly SET_STATUS = "LOCATION_COUNTRY_SET_STATUS";
  public static readonly SET_ERROR = "LOCATION_COUNTRY_SET_ERROR";
  public static readonly CLEAR = "LOCATION_COUNTRY_CLEAR";

  public static setList(data: any) {
    return {
      data,
      type: Country.SET_LIST,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: Country.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: Country.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: Country.CLEAR,
    };
  }
}
