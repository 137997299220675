import { combineReducers } from "redux";

import Incoming from "./Incoming";
import Outgoing from "./Outgoing";
import Saved from "./Saved";

export default combineReducers({
  Incoming,
  Outgoing,
  Saved,
});
