export default class Config {
  public static readonly SET_STATUS = "CONFIG_SET_STATUS";
  public static readonly SET_ERROR = "CONFIG_SET_ERROR";
  public static readonly CLEAR = "CONFIG_CLEAR";

  public static setStatus(data: any) {
    return {
      data,
      type: Config.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: Config.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: Config.CLEAR,
    };
  }
}
