import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  list: [],
  error: "",
};

export default (state = InitialState, action: any) => {
  switch (action.type) {
    case Action.Location.Country.SET_LIST:
      return { ...state, list: action.data };
    case Action.Location.Country.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Location.Country.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Location.Country.CLEAR:
      return { ...state, list: [] };
    default:
      return state;
  }
}
