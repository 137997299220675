export default class Alert {
  public static readonly OPEN = "ALERT_OPEN";
  public static readonly PATCH = "ALERT_PATCH";
  public static readonly CLOSE = "ALERT_CLOSE";
  public static readonly CLOSE_ALL = "ALERT_CLOSE_ALL";

  public static open(data: any): any {
    return {
      data,
      type: Alert.OPEN,
    };
  }

  public static patch(data: any): any {
    return {
      data,
      type: Alert.PATCH,
    };
  }

  public static close(index: string): any {
    return {
      type: Alert.CLOSE,
      data: index,
    };
  }

  public static closeAll(): any {
    return {
      type: Alert.CLOSE_ALL,
    };
  }
}
