export default class NetworkingStand {
  public static readonly SET_LIST = "NETWORKING_STAND_SET_LIST";
  public static readonly SET_STATUS = "NETWORKING_STAND_SET_STATUS";
  public static readonly SET_ERROR = "NETWORKING_STAND_SET_ERROR";
  public static readonly CLEAR = "NETWORKING_STAND_CLEAR";

  public static setList(data: any): any {
    return {
      data,
      type: NetworkingStand.SET_LIST,
    };
  }

  public static setStatus(data: any): any {
    return {
      data,
      type: NetworkingStand.SET_STATUS,
    };
  }

  public static setError(data: any): any {
    return {
      data,
      type: NetworkingStand.SET_ERROR,
    };
  }

  public static clear(): any {
    return {
      type: NetworkingStand.CLEAR,
    };
  }
}
