import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  list: null,
  modifiedList: null,
  error: null,
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export default function BroadcastsSchedule(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Broadcasts.Schedule.SET_LIST:
      return { ...state, list: action.data, modifiedList: action.data };
    case Action.Broadcasts.Schedule.SET_MODIFIED_LIST:
      return { ...state, modifiedList: action.data };
    case Action.Broadcasts.Schedule.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Broadcasts.Schedule.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Broadcasts.Schedule.CLEAR:
      return { ...state, list: null };
    default:
      return state;
  }
}
