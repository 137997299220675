export default class UserCutawaysIncoming {
  public static readonly SET_LIST = "USER_CUTAWAYS_INCOMING_SET_LIST";
  public static readonly SET_COUNTER = "USER_CUTAWAYS_INCOMING_SET_COUNTER";
  public static readonly SET_STATUS = "USER_CUTAWAYS_INCOMING_SET_STATUS";
  public static readonly SET_ERROR = "USER_CUTAWAYS_INCOMING_SET_ERROR";
  public static readonly CLEAR = "USER_CUTAWAYS_INCOMING_CLEAR";

  public static setList(data: any) {
    return {
      data,
      type: UserCutawaysIncoming.SET_LIST,
    };
  }

  public static setCounter(data: any) {
    return {
      data,
      type: UserCutawaysIncoming.SET_COUNTER,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: UserCutawaysIncoming.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: UserCutawaysIncoming.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: UserCutawaysIncoming.CLEAR,
    };
  }
}
