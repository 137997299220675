export default class Footer {
  public static readonly SHOW = "UI_FOOTER_SHOW";
  public static readonly HIDE = "UI_FOOTER_HIDE";

  public static show() {
    return {
      data: { display: true },
      type: Footer.SHOW,
    };
  }

  public static hide() {
    return {
      data: { display: false },
      type: Footer.HIDE,
    };
  }
}
