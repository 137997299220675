import * as Action from "@actions/index";

const initialState = {
  image: null,
};

export default function AvatarChanger(state = initialState, action: any) {
  switch (action.type) {
    case Action.Ui.AvatarChanger.SET_RESULT:
      return { ...state, image: action.data };
    default:
      return state;
  }
}
