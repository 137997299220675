import * as Action from "@actions/index";

const InitialState = {
  listStatus: [],
  listService: [],
  listAudience: [],
  listRevenue: [],
  listAssociations: [],
  isIndia: false,
};

export default (state = InitialState, action: any) => {
  switch (action.type) {
    case Action.Main.India.SET_LIST_STATUS:
      return { ...state, listStatus: action.data };
    case Action.Main.India.SET_LIST_PROVIDE:
      return { ...state, listService: action.data };
    case Action.Main.India.SET_LIST_AUDIENCE:
      return { ...state, listAudience: action.data };
    case Action.Main.India.SET_LIST_REVENUE:
      return { ...state, listRevenue: action.data };
    case Action.Main.India.SET_LIST_ASSOCIATIONS:
      return { ...state, listAssociations: action.data };
    case Action.Main.India.SET_IS_INDIA:
      return { ...state, isIndia: action.data };
    default:
      return state;
  }
};
