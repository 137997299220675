interface IRoom {
  lastactivity: string;
  writing: boolean;
  messages: IMessage[];
}

interface IMessage {
  sender: IUser;
  type: "message" | "cutaway" | "file";
  message?: string;
  cutaway?: IUser;
}

interface IUser {
  id: number;
  firstname: string;
  lastname: string;
  avatar: string;
  email?: string;
  phone?: string;
  position?: string;
}

export default class Chat {
  public static readonly SET_ROOMS = "CHAT_SET_ROOMS";
  public static readonly ADD_ROOMS = "CHAT_ADD_ROOMS";
  public static readonly SET_STATUS = "CHAT_SET_STATUS";
  public static readonly ADD_MESSAGE = "CHAT_ADD_MESSAGE";
  public static readonly ADD_HISTORY = "CHAT_ADD_HISTORY";
  public static readonly CLEAR = "CHAT_CLEAR";

  public static setRooms(data: IRoom): any {
    return {
      data,
      type: Chat.SET_ROOMS,
    };
  }

  public static addRooms(data: IRoom): any {
    return {
      data,
      type: Chat.ADD_ROOMS,
    };
  }

  public static addMessage(data: { messages: IMessage[]; room_id: any }): any {
    return {
      data,
      type: Chat.ADD_MESSAGE,
    };
  }

  public static addHistory(data: { messages: IMessage[]; room_id: any }): any {
    return {
      data,
      type: Chat.ADD_HISTORY,
    };
  }

  public static setStatus(data: string): any {
    return {
      data,
      type: Chat.SET_STATUS,
    };
  }

  public static clear(): any {
    return {
      type: Chat.CLEAR,
    };
  }
}
