import { combineReducers } from "redux";

import Current from "./Current";
import Selected from "./Selected";
import Cutaways from "./Cutaways";
import Events from "./Events";
import Event from "./Event";

export default combineReducers({
  Current,
  Selected,
  Cutaways,
  Events,
  Event,
});
