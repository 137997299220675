import { News } from "@interface/entities/News";
import { BaseAction } from "@interface/common";

export default class NewsItem {
  public static readonly SET_ITEM = "NEWS_SET_ITEM";

  public static setData(data: News): BaseAction<NewsItem> {
    return {
      type: NewsItem.SET_ITEM,
      data,
    };
  }
}
