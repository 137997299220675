import * as Action from "@actions/index";

const InitialState = {
  count: 0,
};

export default function User(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Main.User.SET_COUNT:
      return { ...state, count: action.data };
    default:
      return state;
  }
}
