import * as Action from "@actions/index";
import { Reducer } from "redux";
import { News } from "@interface/entities/News";
import { BaseState, BaseAction } from "@interface/common";

const InitialState = {
  data: [],
};

const reducer: Reducer<BaseState<News[]>, BaseAction<News[]>> = (state = InitialState, action) => {
  switch (action.type) {
    case Action.News.NewsList.SET_LIST:
      return {
        ...state,
        data: [...(action.data ? action.data : [])],
      };

    case Action.News.NewsList.SET_PAGINATOR:
      return {
        ...state,
        paginator: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
