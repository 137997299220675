export default class Schedule {
  public static readonly SET_DATA = "SCHEDULE_SET_DATA";
  public static readonly SET_STATUS = "SCHEDULE_SET_STATUS";
  public static readonly SET_ERROR = "SCHEDULE_SET_ERROR";
  public static readonly CLEAR = "SCHEDULE_CLEAR";

  public static setData(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: Schedule.SET_DATA,
    };
  }

  public static setStatus(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: Schedule.SET_STATUS,
    };
  }

  public static setError(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: Schedule.SET_ERROR,
    };
  }

  public static clear(): { type: string } {
    return {
      type: Schedule.CLEAR,
    };
  }
}
