import * as Action from "@actions/index";
import { Reducer } from "redux";
import { News } from "@interface/entities/News";
import { BaseState, BaseAction } from "@interface/common";

const InitialState = {
  data: null,
};

const reducer: Reducer<BaseState<News | null>, BaseAction<News>> = (
  state = InitialState,
  action
) => {
  switch (action.type) {
    case Action.News.Current.SET_ITEM:
      return { ...state, data: action.data || null };
    default:
      return state;
  }
};

export default reducer;
