import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",
    fallbackLng: false,
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    react: {
      bindI18n: "loaded languageChanged",
      bindI18nStore: "added",
      useSuspense: true,
    },
    backend: {
      loadPath: `${process.env.API_URL}api/spa/{{lng}}/translation/all`,
    },
  });

export default i18n;
