import { News } from "@interface/entities/News";
import { BaseAction } from "@interface/common";

export default class NewsList {
  public static readonly SET_LIST = "NEWS_SET_LIST";
  public static readonly SET_PAGINATOR = "NEWS_SET_PAGINATOR";

  public static setData(data: News): BaseAction<NewsList> {
    return {
      type: NewsList.SET_LIST,
      data,
    };
  }

  public static setPaginator(data: News): BaseAction<NewsList> {
    return {
      type: NewsList.SET_PAGINATOR,
      data,
    };
  }
}
