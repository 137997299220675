import { combineReducers } from "redux";

import Nearest from "./Nearest";
import Schedule from "./Schedule";
import Selected from "./Selected";

export default combineReducers({
  Nearest,
  Schedule,
  Selected,
});
