import * as Action from "@actions/index";

export default function UserCutaway(state = {}, action: any) {
  switch (action.type) {
    case Action.User.Event.SET_STATUS:
      return { ...state, status: action.data };
    case Action.User.Event.SET_ERROR:
      return { ...state, error: action.data };
    case Action.User.Event.CLEAR:
      return { ...state, list: null };
    default:
      return state;
  }
}
