import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  buyersCount: 0,
  sellersCount: 0,
  list: null,
  paginator: null,
  error: null,
};

export default (state = InitialState, action: any) => {
  switch (action.type) {
    case Action.Networking.Company.ADD_LIST:
      return { ...state, list: (state.list || []).concat(action.data) };
    case Action.Networking.Company.SET_BUYERS_COUNT:
      return { ...state, buyersCount: action.data };
    case Action.Networking.Company.SET_SELLERS_COUNT:
      return { ...state, sellersCount: action.data };
    case Action.Networking.Company.SET_PAGINATOR:
      return { ...state, paginator: action.data };
    case Action.Networking.Company.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Networking.Company.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Networking.Company.CLEAR:
      return { ...state, list: null };
    default:
      return state;
  }
};
