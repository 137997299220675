import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  data: null,
  error: null,
};

export default (state = InitialState, action: any): any => {
  switch (action.type) {
    case Action.User.Selected.SET_DATA:
      return { ...state, data: action.data };
    case Action.User.Selected.SET_STATUS:
      return { ...state, status: action.data };
    case Action.User.Selected.SET_ERROR:
      return { ...state, error: action.data };
    case Action.User.Selected.CLEAR:
      return { ...state, data: null };
    default:
      return state;
  }
};
