export default class Auth {
  public static readonly SET_STATUS = "AUTH_SET_STATUS";
  public static readonly SET_DATA = "AUTH_SET_DATA";
  public static readonly SET_JWT = "AUTH_SET_JWT";
  public static readonly SET_PRE_URL = "AUTH_SET_PRE_URL";
  public static readonly SET_EMAIL = "AUTH_SET_EMAIL";
  public static readonly SET_PRE_URL_STATUS = "AUTH_SET_PRE_URL_STATUS";

  public static setStatus(data: any): any {
    return {
      type: Auth.SET_STATUS,
      data,
    };
  }

  public static setData(data: any): any {
    return {
      type: Auth.SET_DATA,
      data,
    };
  }

  public static setJWT(data: string): any {
    return {
      type: Auth.SET_JWT,
      data,
    };
  }

  public static setPreurl(data: any): any {
    return {
      type: Auth.SET_PRE_URL,
      data,
    };
  }

  public static setPreurlStatus(data: any): any {
    return {
      type: Auth.SET_PRE_URL_STATUS,
      data,
    };
  }

  public static setEmail(data: any): any {
    return {
      type: Auth.SET_EMAIL,
      data,
    };
  }
}
