export default class MainIndia {
  public static readonly SET_LIST_STATUS = "MAIN_INDIA_SET_LIST_STATUS";
  public static readonly SET_LIST_PROVIDE = "MAIN_INDIA_SET_LIST_PROVIDE";
  public static readonly SET_LIST_AUDIENCE = "MAIN_INDIA_SET_LIST_AUDIENCE";
  public static readonly SET_LIST_REVENUE = "MAIN_INDIA_SET_LIST_REVENUE";
  public static readonly SET_LIST_ASSOCIATIONS = "MAIN_INDIA_SET_LIST_ASSOCIATIONS";
  public static readonly SET_IS_INDIA = "MAIN_INDIA_SET_IS_INDIA";

  public static setListStatus(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: MainIndia.SET_LIST_STATUS,
    };
  }

  public static setListProvide(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: MainIndia.SET_LIST_PROVIDE,
    };
  }

  public static setListAudience(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: MainIndia.SET_LIST_AUDIENCE,
    };
  }

  public static setListRevenue(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: MainIndia.SET_LIST_REVENUE,
    };
  }

  public static setListAssociations(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: MainIndia.SET_LIST_ASSOCIATIONS,
    };
  }

  public static setIndia(data: boolean): { type: string; data: boolean } {
    return {
      data,
      type: MainIndia.SET_IS_INDIA,
    };
  }
}
