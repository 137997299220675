import * as Action from "@actions/index";

const InitialState = {
  display: false,
};

export default function Userbar(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Ui.Userbar.SHOW:
    case Action.Ui.Userbar.HIDE:
      return { ...state, ...action.data };
    default:
      return state;
  }
}
