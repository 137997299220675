export default class BroadcastsNearest {
  public static readonly SET_LIST = "BROADCASTS_NEAREST_SET_LIST";
  public static readonly SET_STATUS = "BROADCASTS_NEAREST_SET_STATUS";
  public static readonly SET_ERROR = "BROADCASTS_NEAREST_SET_ERROR";
  public static readonly CLEAR = "BROADCASTS_NEAREST_CLEAR";

  public static setList(data: any) {
    return {
      data,
      type: BroadcastsNearest.SET_LIST,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: BroadcastsNearest.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: BroadcastsNearest.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: BroadcastsNearest.CLEAR,
    };
  }
}
