export default class UserSelected {
  public static readonly SET_DATA = "USER_SELECTED_SET_DATA";
  public static readonly SET_STATUS = "USER_SELECTED_SET_STATUS";
  public static readonly SET_ERROR = "USER_SELECTED_SET_ERROR";
  public static readonly CLEAR = "USER_SELECTED_CLEAR";

  public static setData(data: any) {
    return {
      data,
      type: UserSelected.SET_DATA,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: UserSelected.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: UserSelected.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: UserSelected.CLEAR,
    };
  }
}
